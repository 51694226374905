import axios from "axios";

import { config } from "../config";

import { session } from "./session";

export const http = axios.create({ baseURL: config.baseURL });
const companydb = sessionStorage.getItem("companydb")
http.interceptors.request.use((request) => {
	const token = session.get();
	// request.headers = {
	// 	...request.headers,
	// 	companydb: `${companydb}`,
	// };
	if (token) {
		request.headers = {
			...request.headers,
			[config.backendTokenKEY]: `Bearer ${token}`,
			// companydb: `${companydb}`,

		};
	}

	return request;
});
