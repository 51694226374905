import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import Auth from "../pages/auth";
import DepartmentSales from "../pages/sales/from-department/departmentSales";
import Shipments from "../pages/sales/shipments";
import FinishedSales from "../pages/sales/finishedSales";
import Reports from "../pages/reports";
import ReturnedItems from "../pages/sales/returnedItems";

const ProtectedRoute = ({ element: Component, ...rest }) => {
	const isAuthenticated = useSelector((state) => state.main.isAuthenticated);
	return isAuthenticated ? <Component {...rest} /> : <Navigate to="/auth" replace />;
};

const RoutesComponent = () => {
	const isAuthenticated = useSelector((state) => state.main.isAuthenticated);

	return (
		<Routes>
			<Route path="/" element={<Navigate to={isAuthenticated ? "/my-sales" : "/auth"} replace />} />
			<Route path="/auth" element={<Auth />} />

			<Route path="/reports" element={<ProtectedRoute element={Reports} />} />

			<Route path="/from-department" element={<ProtectedRoute element={DepartmentSales} />} />
			<Route path="/returned-items" element={<ProtectedRoute element={ReturnedItems} />} />

			<Route path="/shipments" element={<ProtectedRoute element={Shipments} />} />
			<Route path="/closing-sale" element={<ProtectedRoute element={FinishedSales} />} />
		</Routes>
	);
};

export default RoutesComponent;
