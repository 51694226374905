import React from "react";
import { message } from "antd";
import { http } from '../services/http';

const ChooseCompany = ({ setChooseCompany }) => {
	const handleLoginCompany = async (company, companyName) => {
		try {
			await sessionStorage.setItem("companydb", company);
			http.defaults.headers.common['companydb'] = company;

			sessionStorage.setItem("companyName", companyName);
		} catch (err) {
			message.error(err.message);
		}
	};
	return (
		<div className="flex flex-col gap-10 items-center">
			<div className="flex flex-col md:flex-row gap-6">
				<div
					className={
						"flex flex-col items-center cursor-pointer rounded-xl shadow-md p-5 w-full md:w-[200px] lg:w-[300px]  text-2xl font-bold text-center hover:shadow-xl transition-all duration-300 border-[1px] border-lighter-gray"
					}
					onClick={() => {
						setChooseCompany("Artel");
						handleLoginCompany("TESTARTEL1", "Artel");
					}}
				>
					<p>Artel</p>
				</div>
				<div
					className={
						" flex flex-col items-center cursor-pointer rounded-xl shadow-md p-5 w-full md:w-[200px] lg:w-[300px] text-2xl font-bold text-center hover:shadow-xl transition-all duration-300 border-[1px] border-lighter-gray"
					}
					onClick={() => {
						setChooseCompany("Royal");
						handleLoginCompany("TESTROYAL1", "Royal");
					}}
				>
					<p>Royal</p>
				</div>
			</div>
			<div className="flex flex-col md:flex-row gap-6">
              <div
                className={
                  "flex flex-col items-center cursor-pointer rounded-xl shadow-md p-5 w-full md:w-[200px] lg:w-[300px]  text-2xl font-bold text-center hover:shadow-xl transition-all duration-300 border-[1px] border-lighter-gray"
                }
                onClick={() => {
                  setChooseCompany("awp");
                  handleLoginCompany("TESTAWP1", "awp");
                }}
              >
                <p>AWP</p>
              </div>
				<div
					className={
						" flex flex-col items-center cursor-pointer rounded-xl shadow-md p-5 w-full md:w-[200px] lg:w-[300px] text-2xl font-bold text-center hover:shadow-xl transition-all duration-300 border-[1px] border-lighter-gray"
					}
					onClick={() => {
						setChooseCompany("Katyol");
						handleLoginCompany("TESTKATYOL1", "Katyol");
					}}
				>
					<p>Katyol</p>
				</div>
			</div>
		</div>
	);
};

export default ChooseCompany;
